import { GetterTree } from 'vuex';
import { TranslateItem } from '@/interfaces/TranslateItem';
import { ProfileState } from './types';
import { RootState } from '../types';

const getters: GetterTree<ProfileState, RootState> = {
  getDatabaseData(state): Array<object> {
    const dataMapped = state.sk.map((value: any) => value.sk);
    return dataMapped;
  },
  getAttributes(state): Array<object> {
    const data = state.sk.map((value: any) => value);
    return data;
  },
  getMissingTranslations(state): Array<TranslateItem> {
    const data = state.allTranslations
      .filter((missing: TranslateItem) => missing.translation === undefined || missing.translation === '' || missing.translation.singular === '' || missing.translation.plural === '');
    return data;
  },
  getMissingData(state): TranslateItem {
    return state.missingData;
  },
  getTranslatedItem(state): any {
    return state.translatedItemObject.TranslatedText;
  },
};

export { getters as default };
