import { ActionTree } from 'vuex';
import axios from 'axios';
import { RootState } from '../types';
import { SidebarState } from './types';
import { SidebarMutations } from './mutations';

const actions: ActionTree<SidebarState, RootState> = {
  getMenuItems({ commit, state }) {
    return axios.get(`/sidebar/${encodeURIComponent(state.path)}`)
      .then((response) => {
        commit(SidebarMutations.SET_SIDEBAR_DATA, response.data.resultList.Items);
      });
  },
  async addSidebarCategory({ state }) {
    // console.log(state.newSidebarItem);
    return axios.post('/sidebar', state.newSidebarItem);
  },
  getSearchSourceData({ commit }) {
    return axios.get('/search/searchList')
      .then((response) => {
        commit(SidebarMutations.RECEIVED_SEARCH_DATA, response.data.resultList);
      });
  },
  async deleteSidebarItem({ state }) {
    const response = await axios
      .delete(`/delete/${state.deleteSidebarItem.company}/${state.deleteSidebarItem.division}/${state.deleteSidebarItem.language}/${state.deleteSidebarItem.key}/${state.deleteSidebarItem.type}`);
    return response;
  },
};

export { actions as default };
