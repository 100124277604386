import { MutationTree } from 'vuex';
import { TranslateItem } from '@/interfaces/TranslateItem';
import { TranslationObject } from '@/interfaces/TranslationObject';
import { DeleteItem } from '../../interfaces/DeleteItem';
import { ProfileState } from './types';

export enum TranslateMutations {
  SET_TRANSLATION_DATA = 'SET_TRANSLATION_DATA',
  SET_ROUTE_DATA = 'SET_ROUTE_DATA',
  SET_TRANSLATE_EMPTY = 'SET_TRANSLATE_EMPTY',
  SET_ALL_TRANSLATIONS = 'SET_ALL_TRANSLATIONS',
  SET_MISSING_DATA = 'SET_MISSING_DATA',
  SET_NEW_TRANSLATE_ITEM = 'SET_NEW_TRANSLATE_ITEM',
  SET_OLD_TRANSALTE_ITEM = 'SET_OLD_TRANSALTE_ITEM',
  ADD_SINGLE_TRANSLATION = 'ADD_SINGLE_TRANSLATION',
  ADD_TRANSLATION_JSON = 'ADD_TRANSLATION_JSON',
  TRANSLATED_ITEM_OBJECT = 'TRANSLATED_ITEM_OBJECT',
  TRANSLATE_ITEM_OBJECT = 'TRANSLATE_ITEM_OBJECT',
  CHANGE_VERSION = 'CHANGE_VERSION',
  DELETE_ITEM = 'DELETE_ITEM',
}

const mutations: MutationTree<ProfileState> = {
  [TranslateMutations.SET_TRANSLATION_DATA](state, payload: Array<object>) {
    state.sk = payload;
  },
  [TranslateMutations.SET_ROUTE_DATA](state, payload: TranslationObject) {
    state.translations = payload;
  },
  [TranslateMutations.SET_TRANSLATE_EMPTY](state, payload: Array<object>) {
    state.translation = payload;
  },
  [TranslateMutations.SET_ALL_TRANSLATIONS](state, payload: Array<TranslateItem>) {
    state.allTranslations = payload;
  },
  [TranslateMutations.SET_MISSING_DATA](state, payload: TranslateItem) {
    state.missingData = payload;
  },
  [TranslateMutations.SET_OLD_TRANSALTE_ITEM](state, payload: TranslateItem) {
    state.translationObj = payload;
  },
  [TranslateMutations.SET_NEW_TRANSLATE_ITEM](state, payload: Array<object>) {
    state.translation = payload;
  },
  [TranslateMutations.ADD_SINGLE_TRANSLATION](state, payload: TranslateItem) {
    state.singleTranslation = payload;
  },
  [TranslateMutations.ADD_TRANSLATION_JSON](state, payload: object) {
    state.translationJSON = payload;
  },
  [TranslateMutations.TRANSLATE_ITEM_OBJECT](state, payload: object) {
    state.translateItemObject = payload;
  },
  [TranslateMutations.TRANSLATED_ITEM_OBJECT](state, payload: object) {
    state.translatedItemObject = payload;
  },
  [TranslateMutations.CHANGE_VERSION](state, payload: object) {
    state.versionObj = payload;
  },
  [TranslateMutations.DELETE_ITEM](state, payload: DeleteItem) {
    state.deleteItem = payload;
  },
};

export { mutations as default };
