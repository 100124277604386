































































































































import {
  Component, Vue, VModel, Watch,
} from 'vue-property-decorator';
import CountryLanguage from 'country-language';
import Multiselect from 'vue-multiselect';
import { SidebarMutations } from '@/store/sidebar/mutations';
import { Sidebar } from '@/interfaces/Sidebar';
import '../../node_modules/vue-multiselect/dist/vue-multiselect.min.css';
import { TranslateMutations } from '@/store/translations/mutations';

@Component({
  components: {
    Multiselect,
  },
})
export default class AddItemModel extends Vue {
  @VModel({ type: Boolean }) modalStatus!: boolean;

  categoryType = 'LABEL';

  category = '';

  saving = false;

  logoLocation = '';

  logoAlt = '';

  sidebarParents = [];

  parentDropDownData: any = {};

  parentDropDown = false;

  iconPickerDropdown = false;

  languageDropdown = false;

  showAllLanugages = [];

  iconPicker = 'fab fa-500px';

  isHomeRoute = true;

  url = '';

  multiDropValue: any = [];

  selectedCountries = [];

  countries = [];

  isLoading = false;

  error = '';

  @Watch('modalStatus')
  async fetchSidebarData(value: boolean) {
    const { name, division } = this.$route.params;
    if (value) {
      // Fetch all sidebar data before page loades
      const data: any = await this.fetchParentHomeSidebarData();
      this.sidebarParents = data.filter((item: any) => item.divisionType === division);

      // If params are filled let the page know it's not on the homepage
      if (name && division) {
        this.isHomeRoute = false;
      }
    }
  }

  @Watch('modalStatus')
  clearModal() {
    if (this.modalStatus) {
      this.categoryType = 'LABEL';
      this.category = '';
      this.logoLocation = '';
      this.logoAlt = '';
      this.parentDropDown = false;
      this.iconPickerDropdown = false;
      this.languageDropdown = false;
      this.showAllLanugages = [];
      this.iconPicker = 'fab fa-500px';
      this.multiDropValue = [];
      this.selectedCountries = [];
      this.countries = [];
    }
  }

  // If url is home route return Menu
  // If url is not home route return routename#Menu
  getUrl() {
    // Fill this.urlData with router params or with Menu
    return (this.$route.path === '/' ? 'Menu' : `${this.$route.params.name}#Menu`);
  }

  setIcon(value: any) {
    this.iconPicker = value;
    this.iconPickerDropdown = !this.iconPickerDropdown;
  }

  // Fill countries variable with search value
  searchLanguage(query: any) {
    this.isLoading = true;
    this.searchLanguages(query).then((response: any) => {
      this.countries = response;
      this.isLoading = false;
    });
  }

  // return array with possible languages user entered
  // eslint-disable-next-line class-methods-use-this
  async searchLanguages(name: any): Promise<object[]> {
    const newArr = this.listOfLanguages
      .filter((item: any) => item.name.toLowerCase().indexOf(name.toLowerCase()) > -1);

    return newArr;
  }

  // Return all languages with a iso639_1 value
  // eslint-disable-next-line class-methods-use-this
  get listOfLanguages() {
    const allLanguages = CountryLanguage.getLanguages();

    const allLanguagesObject = allLanguages.map((language: any) => {
      const langCode = language.iso639_1;
      const [langName] = language.name;

      if (langCode !== '') {
        return { code: langCode, name: langName };
      }

      return false;
    });

    return allLanguagesObject.filter((item: any) => item !== false);
  }

  get categoryInput() {
    return this.category;
  }

  set categoryInput(value: string) {
    const category = value.trim().toLowerCase().replace(/[^A-Z0-9]+/ig, '');
    this.$nextTick(() => {
      this.category = category;
    });
  }

  async fetch() {
    // Fill this.data with router params
    const data = {
      company: `${this.$route.params.name}#Project`,
      division: this.$route.params.division,
    };

    // Commit this.data to mutation
    this.$store.commit(TranslateMutations.SET_ROUTE_DATA, data);

    // Call action getSpecificTranslations
    await this.$store.dispatch('getSpecificTranslations');
  }

  // Get new fetchParentHomeSidebarData to refresh sidebar data
  // Set all data to empty data
  @Watch('$store.getters.getPageRefresh')
  refreshData() {
    const { division } = this.$route.params;
    this.fetchParentHomeSidebarData().then((data: any) => {
      this.sidebarParents = data.filter((item: any) => item.divisionType === division);
    });
    this.categoryType = '';
    this.category = '';
    this.logoLocation = '';
    this.logoAlt = '';
    this.iconPicker = '';
    this.error = '';
  }

  @Watch('categoryType')
  switchTab() {
    this.error = '';
  }

  // Active when click on dropdown item
  dropdownSelect(data: any) {
    // Set parentDropDownData with all data from dropdown
    this.parentDropDownData = data;
    // Set dropdown to true or false
    this.parentDropDown = !this.parentDropDown;
  }

  // Return parent data from sidebar
  async fetchParentHomeSidebarData(): Promise<Sidebar[]> {
    this.url = this.getUrl();

    // Commit this.urlData to mutation
    this.$store.commit(SidebarMutations.SET_SIDEBAR_PATH, this.url);

    // Call action getSpecificTranslations
    await this.$store.dispatch('getMenuItems');

    // Receive all sidebar text
    return this.$store.getters.getSidebarParents;
  }

  // Return filled parent data
  async fillSidebarParent(category: string, type: string): Promise<object> {
    const { division } = this.$route.params;

    return {
      pk: this.url,
      sk: (this.url === 'Menu' ? category : `${category}#${division}`),
      text: category,
      icon: '',
      type,
      divisionType: division,
    };
  }

  // Return filled child data
  async fillSidebarChild(category: string, type: string, logo: string): Promise<object> {
    const { division } = this.$route.params;

    const sidebarChild = {
      pk: this.url,
      sk: (this.url === 'Menu' ? `${this.parentDropDownData.sk}#${category}` : `${this.parentDropDownData.sk.split('#')[0]}#${category}#${division}`),
      eventName: (this.url === 'Menu' ? `${this.parentDropDownData.sk}${category}` : `${this.parentDropDownData.sk.split('#')[0]}${category}`),
      icon: logo,
      parent: this.parentDropDownData.sk,
      text: category,
      missing: 0,
      type,
      divisionType: division,
    };
    return sidebarChild;
  }

  // Return filled settings data (Only called when adding new company)
  async fillSidebarSettings(logo: string, altText: string, parent: string) {
    const sidebarSettings = {
      pk: this.url,
      sk: `${parent}#Settings`,
      alt: altText,
      icon: '',
      parent,
      src: logo,
    };
    return sidebarSettings;
  }

  // Return filled languages data (Only called when adding new division)
  // eslint-disable-next-line class-methods-use-this
  async fillSidebarLanguages(parent: string, division: string, languages: Array<string>) {
    const parentLower = parent.toLowerCase();
    const divisionLower = division.toLowerCase();
    const languageArray = languages.map((language: any) => `${language.code.toLowerCase()}-${language.code.toUpperCase()}`);

    const projectLanguages = {
      pk: `${parentLower}#Project`,
      sk: `${divisionLower}#languages`,
      languages: languageArray,
    };

    return projectLanguages;
  }

  // Save new sidebar item
  async saveNewSidebarItem() {
    if (!this.saving) {
      this.saving = true;
      this.category = this.category.toLowerCase().replace(/[^A-Z0-9]+/ig, '');
      let sidebarItem = {};
      let sidebarSettingsItem = {};
      let projectLanguageSettings = {};
      let sidebarParentData = '';

      if (Object.keys(this.parentDropDownData).length === 0 && this.categoryType === 'CUSTOM' && this.url === 'Menu') {
        this.error = 'ERROR: You need to select a parent.';
        return;
      }

      if (this.category.length === 0) {
        this.error = 'ERROR: Item name is empty.';
        return;
      }

      if (this.selectedCountries.length === 0 && this.categoryType === 'CUSTOM' && this.url === 'Menu') {
        this.error = 'ERROR: You need to select a language.';
        return;
      }

      if (this.url === 'Menu') {
        // Check if company name already exitst
        if (this.categoryType === 'LABEL') {
          let duplicateError = false;
          const parentData: any = await this.fetchParentHomeSidebarData();
          // eslint-disable-next-line array-callback-return
          await parentData.map((parent: any) => {
            if (parent.text === this.category) {
              duplicateError = true;
            }
          });

          if (duplicateError) {
            this.error = 'ERROR: This company name already exists.';
            return;
          }
        }

        // Check if division already exitst
        if (this.categoryType === 'CUSTOM') {
          // Commit this.urlData to mutation
          this.$store.commit(SidebarMutations.SET_SIDEBAR_PATH, this.url);

          // Call action getMenuItems
          await this.$store.dispatch('getMenuItems');

          // Receive all sidebar text
          const sidebarChildData = this.$store.getters.getSidebarData
            .filter((child: any) => child.type === 'CUSTOM' && child.parent === this.parentDropDownData.sk);

          let duplicateError = false;
          // eslint-disable-next-line array-callback-return
          await sidebarChildData.map((child: any) => {
            if (child.text === this.category) {
              duplicateError = true;
            }
          });

          if (duplicateError) {
            this.error = 'ERROR: This division name already exists.';
            return;
          }
        }
      }

      if (this.url !== 'Menu') {
        // Check if label name already exitst
        if (this.categoryType === 'LABEL') {
          let duplicateError = false;
          const parentData: any = await this.fetchParentHomeSidebarData();
          // eslint-disable-next-line array-callback-return
          await parentData.map((parent: any) => {
            if (parent.text === this.category) {
              duplicateError = true;
            }
          });

          if (duplicateError) {
            this.error = 'ERROR: This label name already exists.';
            return;
          }
        }

        // Check if custom item already exitst
        if (this.categoryType === 'CUSTOM') {
          // Commit this.urlData to mutation
          this.$store.commit(SidebarMutations.SET_SIDEBAR_PATH, this.url);

          // Call action getMenuItems
          await this.$store.dispatch('getMenuItems');

          // Receive all sidebar text
          const sidebarChildData = this.$store.getters.getSidebarData
            .filter((child: any) => child.type === 'CUSTOM' && child.parent === this.parentDropDownData.sk);

          let duplicateError = false;
          // eslint-disable-next-line array-callback-return
          await sidebarChildData.map((child: any) => {
            if (child.text === this.category) {
              duplicateError = true;
            }
          });

          if (duplicateError) {
            this.error = 'ERROR: This sub category already exists.';
            return;
          }
        }
      }

      // Check if type is LABEL. If it is, it's a parent otherwise it's a child
      if (this.categoryType === 'LABEL') {
        await this.fillSidebarParent(this.category, this.categoryType).then((data: any) => {
          sidebarParentData = data.sk;
          sidebarItem = data;
        });
      } else {
        await this.fillSidebarChild(
          this.category, this.categoryType, this.iconPicker,
        ).then(async (data: any) => {
          sidebarItem = data;
        });
      }

      // Post sidebar data to database
      this.$store.commit(SidebarMutations.SET_NEW_SIDEBAR_DATA, sidebarItem);
      await this.$store.dispatch('addSidebarCategory');

      // If type is LABEL and url is Menu add settings to item because it's a new company
      if (this.categoryType === 'LABEL' && this.url === 'Menu') {
        await this.fillSidebarSettings(this.logoLocation, this.logoAlt, sidebarParentData)
          .then((data) => {
            sidebarSettingsItem = data;
          });

        // Post settings to database
        this.$store.commit(SidebarMutations.SET_NEW_SIDEBAR_DATA, sidebarSettingsItem);
        await this.$store.dispatch('addSidebarCategory');
      }

      // If type is not a LABEL and its Menu add settings to dabase because its a child
      if (this.categoryType !== 'LABEL' && this.url === 'Menu') {
        // Set languages
        await this.fillSidebarLanguages(
          this.parentDropDownData.text,
          this.category,
          this.selectedCountries,
        ).then((data: any) => {
          projectLanguageSettings = data;
        });

        // Post selected languages to database
        this.$store.commit(SidebarMutations.SET_NEW_SIDEBAR_DATA, projectLanguageSettings);
        await this.$store.dispatch('addSidebarCategory');
      }
    }

    // Commit update to store so app knows to update data
    this.$store.commit(SidebarMutations.SET_UPDATE_SIDEBAR);
    this.closeModal();
    this.saving = false;
  }

  // Return dropdown item text
  selectedCategoryLabel(): string {
    if (Object.keys(this.parentDropDownData).length !== 0) {
      return this.parentDropDownData.text;
    }

    return 'Choose parent';
  }

  closeModal() {
    this.modalStatus = false;
  }
}
