import axios from 'axios';
import authModule from '@index-software/auth/src';
import store from '@/store';
import stack from '../../../../cdk.out/stack.json';

axios.defaults.baseURL = stack.translateBackendStack.APIUrl;
// Add a request interceptor
axios.interceptors.request.use(
  async (config) => {
    // Create a new config
    const configResult = config;
    if (store.getters[authModule.store.types.GETTER_TOKEN_EXPIRED]) {
      await store.dispatch(
        authModule.store.types.ACTION_REFRESH_AUTHENTICATION,
        store.getters[authModule.store.types.GETTER_AUTH_REFRESH_TOKEN],
      );
    }
    configResult.headers = {
      accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: store.getters[authModule.store.types.GETTER_AUTH_AUTHENTICATION].IdToken || '',
    };
    return Promise.resolve(configResult);
  },
  // console.log(error);
  // Do something with request error
  async (error) => Promise.reject(error),
);
// Add a response interceptor
axios.interceptors.response.use(
  // console.log(response);
  // Do something with response
  async (response) => Promise.resolve(response),
  // console.log(error);
  // Do something with response error
  async (error) => Promise.reject(error),
);

export default axios;
