































import { Component, Vue, Watch } from 'vue-property-decorator';
import { TranslateMutations } from '@/store/translations/mutations';
import AddKeyModal from '@/components/AddKeyModal.vue';

@Component({
  components: {
    AddKeyModal,
  },
})
export default class TranslateSidebar extends Vue {
  extraSideBar = true;

  data = {};

  sidebarLoaded = false;

  extraSidebarContent: any = [];

  databaseContent: any = [];

  availableLanguages: any = [];

  category = '';

  keyModal = false;

  searchList = [];

  async created() {
    if (this.$route.params.menu !== undefined) {
      this.openSidebar(this.$route.params.menu);
    } else {
      await this.fetch();
      await this.openSidebar();
    }
  }

  @Watch('$store.getters.getSearchValue')
  async searchValue() {
    this.databaseContent = await this.$store.getters.getAttributes;
    this.searchList = [];
    const newArr: any = [];

    const inputArray = this.databaseContent
      .filter((data: any) => data.type !== undefined)
      .filter((data: any) => data.type.toLowerCase() === this.category.toLowerCase())
      .map((value: any) => ({
        key: value.key,
        translationSingular: value.translation.singular.toLowerCase(),
        translationPlural: value.translation.plural.toLowerCase(),
      }));

    function filterIt(arr: any, searchKey: any) {
      return arr
        .filter((obj: any) => Object.keys(obj).some((key: any) => obj[key].includes(searchKey)));
    }

    await filterIt(inputArray, this.$store.getters.getSearchValue)
      .map((searchedItem: any) => newArr.push(searchedItem.key));

    this.extraSidebarContent = [...new Set(newArr)];

    this.searchList = this.extraSidebarContent;
  }

  addKeysModalStatus() {
    this.keyModal = true;
  }

  @Watch('$store.getters.getPageRefresh')
  async refreshPageFromUpdate() {
    if (this.$route.params.menu !== undefined) {
      this.openSidebar(this.$route.params.menu);
    } else {
      await this.fetch();
      await this.openSidebar();
    }
  }

  @Watch('$route')
  async routeChange() {
    if (this.$route.params.menu !== undefined) {
      this.openSidebar(this.$route.params.menu);
    } else {
      if (this.$route.hash.length > 0 && !this.$route.params.fromDropDown) {
        return;
      }
      this.searchList = [];

      await this.fetch();
      await this.openSidebar();
    }
  }

  isActive(item: any) {
    const { name, division, type } = this.$route.params;
    const routeTranslation = item;

    if (this.$route.path === `/translate/${name}/${division}/${type}/${routeTranslation}`) {
      return true;
    }

    return false;
  }

  async openSidebar(content?: any) {
    // Set translations
    if (content === undefined) {
      this.databaseContent = await this.$store.getters.getAttributes;
    } else {
      this.databaseContent = content;
    }

    this.category = this.$route.params.type;

    this.availableLanguages = this.databaseContent
      .filter((data: any) => data.type === undefined)
      .flatMap((value: any) => value.languages.map((values: any) => values.split('-')[1]));

    // Compare category with data.type and return all items in extra sidebar
    const inputArray = this.databaseContent
      .filter((data: any) => data.type !== undefined)
      .filter((data: any) => data.type.toLowerCase() === this.category.toLowerCase())
      .map((value: any) => value.key);

    if (this.searchList.length > 0) {
      // Search list is filled
      this.extraSidebarContent = this.searchList;
    } else {
      // Remove all duplicates
      this.extraSidebarContent = [...new Set(inputArray)];
    }

    return this.extraSidebarContent;
  }

  async fetch() {
    this.sidebarLoaded = false;
    this.extraSidebarContent = [];
    // Fill this.data with router params
    this.data = {
      company: `${this.$route.params.name}#Project`,
      division: this.$route.params.division,
    };

    // Commit this.data to mutation
    this.$store.commit(TranslateMutations.SET_ROUTE_DATA, this.data);

    // Call action getSpecificTranslations
    await this.$store.dispatch('getSpecificTranslations');
    this.sidebarLoaded = true;
  }

  async clickedSidebar(item: any) {
    if (!this.isActive(item)) {
      const getLanguages = await this.$store.getters.getAttributes
        .find((fetchedData: any) => fetchedData.languages !== undefined);

      const [language] = getLanguages.languages;
      const [singleLangValue] = language.split('-');

      this.$router.push({
        name: 'Translations',
        params: {
          type: `${this.category}`,
          translation: `${item}`,
        },
        hash: `#${singleLangValue}`,
      }).catch(() => null);
    }
  }
}
