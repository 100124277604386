































import {
  Component, Vue, VModel, Watch,
} from 'vue-property-decorator';
import { SidebarMutations } from '@/store/sidebar/mutations';
import { TranslateMutations } from '@/store/translations/mutations';

@Component
export default class ImportModal extends Vue {
  @VModel({ type: Boolean }) modalStatus!: boolean;

  uploading = false;

  loading = true;

  logLanguageMessages: any = [];

  error = false;

  loadingIcons = [
    'fa fa-male man',
    'fa fa-transporter-empty man',
    'fa fa-transporter man',
    'fa fa-transporter-1 man',
    'fa fa-transporter-2 man',
    'fa fa-transporter-3 man',
    'fa fa-transporter-2 man',
    'fa fa-transporter-1 man',
    'fa fa-female woman',
    'fa fa-transporter-empty woman',
    'fa fa-transporter woman',
    'fa fa-transporter-1 woman',
    'fa fa-transporter-2 woman',
    'fa fa-transporter-3 woman',
    'fa fa-transporter-2 woman',
    'fa fa-transporter-1 woman',
  ];

  selectedIcon = '';

  changeIcon() {
    let index = 0;

    setInterval(() => {
      if (index <= this.loadingIcons.length - 1) {
        this.selectedIcon = this.loadingIcons[index];
        index += 1;
      } else {
        index = 0;
        this.selectedIcon = this.loadingIcons[index];
      }
    }, 200);
  }

  async getDatabaseValues() {
    const { name, division } = this.$route.params;

    const urlData = {
      company: `${name}#Project`,
      division,
    };

    this.$store.commit(TranslateMutations.SET_ROUTE_DATA, urlData);
    await this.$store.dispatch('getSpecificTranslations');
    return this.$store.getters.getAttributes;
  }

  async runUpload(fileData: File) {
    this.changeIcon();
    this.modalStatus = false;
    this.uploading = true;

    let dataFromJson = '';

    // Read data from JSON
    try {
      dataFromJson = await new Response(fileData).json();
    } catch (e) {
      // Give error
      this.error = true;
      this.loading = false;
    }

    const { name, division } = this.$route.params;

    // Get all languages
    const languages = await this.getDatabaseValues();

    // Upload data to database
    try {
      await Promise.all(Object.keys(dataFromJson).map(async (key, index) => {
        // Add sidebar parents
        const sidebarItem = {
          divisionType: division,
          icon: '',
          pk: `${name}#Menu`,
          sk: `${key}#${division}`,
          text: key,
          type: 'LABEL',
        };

        this.$store.commit(SidebarMutations.SET_NEW_SIDEBAR_DATA, sidebarItem);
        await this.$store.dispatch('addSidebarCategory');

        // Add sidebar children
        const parentLoop = Object.keys(dataFromJson[key]).map(async (childKey: any, index) => {
          const sidebarItemChild = {
            divisionType: division,
            eventName: key + childKey,
            icon: '',
            missing: 0,
            parent: `${key}#${division}`,
            pk: `${name}#Menu`,
            sk: `${key}#${childKey}#${division}`,
            text: childKey,
            type: 'CUSTOM',
          };

          this.$store.commit(SidebarMutations.SET_NEW_SIDEBAR_DATA, sidebarItemChild);
          await this.$store.dispatch('addSidebarCategory');

          // Check amount of translations and add them
          const childLoop = Object.keys(dataFromJson[key][childKey])
            .map(async (translationKey: any, index) => {
              const langLoop = Object.keys(dataFromJson[key][childKey][translationKey])
                .map(async (language: any, index) => {
                // Check if the languages are correct
                  if (!languages.map((data: any) => data.languages).flat().includes(language)) {
                    if (!this.logLanguageMessages.includes(language)) {
                      this.logLanguageMessages.push(language);
                      return false;
                    }
                    return false;
                  }

                  const translationItem = {
                    key: translationKey,
                    language,
                    pk: `${name}#Project`,
                    sk: `${division}#${language}#${translationKey}#${childKey}`,
                    translation: {
                      singular: dataFromJson[key][childKey][translationKey][language].singular,
                      plural: dataFromJson[key][childKey][translationKey][language].plural,
                    },
                    type: childKey,
                  };

                  this.$store.commit(TranslateMutations.ADD_SINGLE_TRANSLATION, translationItem);
                  await this.$store.dispatch('addTranslation');

                  return true;
                });
              return Promise.all(langLoop);
            });
          return Promise.all(childLoop);
        });
        return Promise.all(parentLoop);
      }));
    } catch (e) {
      // Give error
      this.error = true;
      this.loading = false;
    }

    const loadTranslations = await this.getDatabaseValues();

    const languagesFiltered = loadTranslations
      .map((data: any) => data.languages)
      .filter((language: any) => language)
      .flat();

    const items: any = { data: {} };

    items.company = name;
    items.division = division;

    // Set data to JSON file in s3
    try {
      await Promise.all(languagesFiltered.map((parent: any) => {
        const children = loadTranslations
          .filter((key: any) => key.languages === undefined)
          .map((child: any) => child);
        const filterdChildren: any = children.filter((item: any) => item.language === parent);

        const typeData = filterdChildren.map((item: any) => item.type);
        const typeFilterd: any = [...new Set(typeData)];

        if (items.data[parent] === undefined) {
          items.data[parent] = {};
        }

        // Loop through types
        typeFilterd.map((data: any) => {
          // Append type to language
          Object.assign(items.data[parent], { [data]: {} });
          // Check if types are the same
          filterdChildren
            .filter((itemData: any) => itemData.type === data)
            .map((itemData: any) => {
              // Append key value to attribute
              Object.assign(items.data[parent][data], { [itemData.key]: {} });
              // Append singular and plural to key value
              items.data[parent][data][itemData.key] = itemData.translation;
              return itemData;
            });
          return data;
        });
        return true;
      }));

      const versionObj = {
        pk: `${name}#Project`,
        sk: `${division}#languages`,
        languages: languagesFiltered,
        version: Date.now(),
      };

      this.$store.commit(TranslateMutations.CHANGE_VERSION, versionObj);
      await this.$store.dispatch('changeVersionNumber');

      this.$store.commit(TranslateMutations.ADD_TRANSLATION_JSON, items);
      await this.$store.dispatch('addTranslationJSON');

      this.loading = false;
      // Commit update to store so app knows to update data
      this.$store.commit(SidebarMutations.SET_UPDATE_SIDEBAR);
    } catch (e) {
      // Give error
      this.error = true;
      this.loading = false;
    }
  }

  closeModal() {
    this.loading = true;
    this.error = false;
    this.modalStatus = false;
    this.uploading = false;
    this.logLanguageMessages = [];
  }
}
