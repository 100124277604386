import Vue from 'vue';
import {
  IcGrid,
  IcButton,
  IcInputText,
  IcInputIconWrapper,
  IcInputTableWrapper,
  IcTextarea,
  IcDropdown,
  IcModal,
  IcSidebar,
  IcSidebarGrid,
  IcIconpicker,
  IcFileUpload,
  IcLoader,
  IcCheckbox,
} from '@index-software/index-core-vue';

Vue.component('IcGrid', IcGrid);
Vue.component('IcButton', IcButton);
Vue.component('IcInputText', IcInputText);
Vue.component('IcInputIconWrapper', IcInputIconWrapper);
Vue.component('IcInputTableWrapper', IcInputTableWrapper);
Vue.component('IcTextarea', IcTextarea);
Vue.component('IcDropdown', IcDropdown);
Vue.component('IcModal', IcModal);
Vue.component('IcSidebar', IcSidebar);
Vue.component('IcSidebarGrid', IcSidebarGrid);
Vue.component('IcIconpicker', IcIconpicker);
Vue.component('IcFileUpload', IcFileUpload);
Vue.component('IcLoader', IcLoader);
Vue.component('IcCheckbox', IcCheckbox);
