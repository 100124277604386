import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import authModule from '@index-software/auth/src';
import { RootState } from './types';
import translation from './translations/index';
import sidebar from './sidebar/index';
import stack from '../../../../cdk.out/stack.json';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    auth: authModule.store.store(stack.translateCognitoStack.UserPoolClientId),
    translation,
    sidebar,
  },
};

export default new Vuex.Store<RootState>(store);
