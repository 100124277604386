




























































import { Component, Vue, Watch } from 'vue-property-decorator';
import SidebarHome from '@/components/SidebarHome.vue';
import { Sidebar } from '@/interfaces/Sidebar';
import { SidebarMutations } from '@/store/sidebar/mutations';
import { TranslateItem } from '@/interfaces/TranslateItem';
import { TranslateMutations } from '@/store/translations/mutations';

@Component({
  components: {
    SidebarHome,
  },
})
export default class Home extends Vue {
  open = true;

  companies: Sidebar[] = [];

  companiesLoaded = false;

  childrenLoaded = false;

  missingTranslationsLoaded = false;

  children: any = [];

  username = 'Gebruiker';

  missingTranslations: Array<TranslateItem> = [];

  async created() {
    this.fetchMissingTranslations();
    this.getChildItems();
    this.getMenuItems();
  }

  @Watch('$store.getters.getPageRefresh')
  async refreshPage() {
    this.fetchMissingTranslations();
    this.getChildItems();
    this.getMenuItems();
  }

  async getMenuItems() {
    // 'Menu' is the default menu route
    const url = 'Menu';

    await this.fetch(url).then((data: any) => {
      const parents = data.filter((companies: any) => companies.type === 'LABEL')
        .map((parent: any) => {
          const settings = data.find((setting: any) => `${parent.sk}#Settings` === setting.sk);
          return {
            ...parent,
            alt: settings.alt,
            src: settings.src,
          };
        });
      this.companies = parents;
      this.companiesLoaded = true;
    });
  }

  async getChildItems() {
    // 'Menu' is the default menu route
    const url = 'Menu';

    await this.fetchMissingTranslations().then(async (data: any) => {
      const missingData = data;
      // Group all item with each other
      // eslint-disable-next-line max-len
      const newArr = data.filter((v: any, i: any, a: any) => a.findIndex((t: any) => (t.key === v.key && t.type === v.type && t.pk === v.pk)) === i);

      const groupedData = newArr.map((item: any) => {
        const newList = data
          // eslint-disable-next-line max-len
          .filter((missingItem: any) => missingItem.type === item.type && missingItem.key === item.key && missingItem.pk === item.pk)
          .map((newListdata: any) => newListdata.language);
        return {
          ...item,
          same: newList,
        };
      });
      this.missingTranslations = groupedData;
      this.missingTranslationsLoaded = true;

      this.fetch(url).then((data: any) => {
        const childData = data
          .filter((child: any) => child.type !== 'LABEL' && child.src === undefined)
          .map((newValues: any) => {
            // Compares every missingTranslations sk with childData sk
            const missing = missingData.filter((value: any) => `${value.pk.split('#')[0]}#${value.sk.split('#')[0]}` === newValues.sk);
            return {
              ...newValues,
              missing: missing.length,
            };
          });
        this.children = childData;
        this.childrenLoaded = true;
      });
    });
  }

  async fetch(url: string): Promise<Sidebar[]> {
    // Commit this.urlData to mutation
    this.$store.commit(SidebarMutations.SET_SIDEBAR_PATH, url);

    // Call action getMenuItems
    await this.$store.dispatch('getMenuItems');

    // Receive all sidebar text
    return this.$store.getters.getSidebarData;
  }

  async fetchMissingTranslations() {
    // Call action getAllTranslations
    await this.$store.dispatch('getAllTranslations');

    // Receive all missing translation items
    return this.$store.getters.getMissingTranslations;
  }

  async makeRouterLink(project: any) {
    await this.fetchFirstItem(project.parent, project.text)
      .then((data: any) => {
        this.$router.push({
          name: 'Translations',
          params: {
            name: project.parent,
            division: project.text,
            type: data.type,
            translation: data.key,
            sidebarData: project,
          },
          hash: `#${data.singleLangValue}`,
        }).catch(() => null);
      }).catch(() => {
        this.$router.push({
          name: 'TranslateDetail',
          params: {
            name: project.parent,
            division: project.text,
            sidebarData: project,
          },
        }).catch(() => null);
      });
  }

  async fetchFirstItem(parent: string, division: string) {
    // Fill this.data with router params
    const data = {
      company: `${parent}#Project`,
      division,
    };

    // Commit this.data to mutation
    this.$store.commit(TranslateMutations.SET_ROUTE_DATA, data);

    // Call action getSpecificTranslations
    await this.$store.dispatch('getSpecificTranslations');

    const listOfAvailable = await this.$store.getters.getAttributes
      .filter((fetchedData: any) => fetchedData.languages === undefined);

    const getLanguages = await this.$store.getters.getAttributes
      .find((fetchedData: any) => fetchedData.languages !== undefined);

    // Pick first key and type from array and put in const
    const [{ key, type }] = listOfAvailable;
    const [language] = getLanguages.languages;
    const [singleLangValue] = language.split('-');

    return { type, key, singleLangValue };
  }

  async buttonClick(item: any) {
    const [parent] = item.pk.split('#');
    const [division] = item.sk.split('#');
    const [language] = item.language.split('-');

    this.$router.push({
      name: 'Translations',
      hash: `#${language}`,
      params: {
        name: `${parent}`,
        division: `${division}`,
        type: `${item.type}`,
        translation: `${item.key}`,
      },
    }).catch(() => null);
  }
}
