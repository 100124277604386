import { GetterTree } from 'vuex';
import { Sidebar } from '../../interfaces/Sidebar';
import { SidebarState } from './types';
import { RootState } from '../types';

const getters: GetterTree<SidebarState, RootState> = {
  getSidebarData(state): Sidebar[] {
    const parents = state.translations.filter((companies: any) => companies.type === 'LABEL')
      .flatMap((parent: any) => {
        const children = state.translations.filter((child: any) => (child.parent === parent.sk));

        return [{ ...parent }, ...children];
      });

    return parents;
  },
  getSidebarCategory(state): string {
    return state.category;
  },
  getIcons(state): Array<object> {
    const iconData = state.translations.filter((data: any) => data.sk.split('#')[0] === 'iconItems');
    return iconData;
  },
  getSidebarParents(state): any {
    const parents = state.translations.filter((parent: any) => parent.type === 'LABEL');
    return parents;
  },
  getPageRefresh(state): any {
    return state.updatePage;
  },
  getSearchValue(state): string {
    return state.searchValue;
  },
  getSearchData(state): string {
    return state.databaseSearchValue;
  },
};

export { getters as default };
