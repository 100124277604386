







































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import CountryLanguage from 'country-language';
import { TranslateMutations } from '@/store/translations/mutations';
import { DeleteItem } from '../interfaces/DeleteItem';

@Component
export default class TranslateContent extends Vue {
  data = {};

  translations: any = [];

  databaseContent = [];

  availableLanguages: any = [];

  availableLanguagesConverted: any = [];

  singularTranslationData = '';

  pluralTranslationData = '';

  pluralError = false;

  loaded = false;

  singularError = false;

  translateItemString = '';

  translatedItem = '';

  isSaving = 3;

  deleteModal = false;

  checkboxValueData = false;

  inputFieldData: any = [];

  addPropModal = false;

  modalNameInputField = '';

  deletedKeys: any = [];

  async created() {
    this.loadData();
  }

  closeModal() {
    this.deleteModal = false;
    this.addPropModal = false;
  }

  deleteKey() {
    this.deleteModal = true;
  }

  async deleteItem() {
    let noJSON = false;
    let jsonData: any = {};
    this.isSaving = 0;
    const {
      name, division, type, translation,
    } = this.$route.params;

    // Receive translate object
    try {
      jsonData = await this.$store.dispatch('getTranslationsObject', { project: name, division });
    } catch (e) {
      noJSON = true;
    }

    await this.availableLanguages.map((language: any) => {
      const deleteObj: DeleteItem = {
        company: name,
        division,
        language,
        key: translation,
        type,
      };

      if (!noJSON) {
        if (jsonData[language] !== undefined) {
          delete jsonData[language][type][translation];
        }
      }

      this.$store.commit(TranslateMutations.DELETE_ITEM, deleteObj);
      this.$store.dispatch('deleteTranslation');
      return language;
    });

    if (!noJSON) {
      const newJsonObj = {
        company: name,
        data: jsonData,
        division,
      };

      this.$store.commit(TranslateMutations.ADD_TRANSLATION_JSON, newJsonObj);
      await this.$store.dispatch('addTranslationJSON');

      const versionObj = {
        pk: `${name}#Project`,
        sk: `${division}#languages`,
        languages: this.availableLanguages,
        version: Date.now(),
      };

      // Update version
      this.$store.commit(TranslateMutations.CHANGE_VERSION, versionObj);
      await this.$store.dispatch('changeVersionNumber');
    }

    await new Promise((resolve: any) => setTimeout(resolve, 500));

    this.isSaving = 2;

    await new Promise((resolve: any) => setTimeout(resolve, 500));

    this.$router.push({
      name: 'TranslateSidebar',
    }).catch(() => null);
  }

  @Watch('$route')
  async initRefresh() {
    this.loadData();
  }

  async loadData() {
    this.loaded = false;
    await this.fetch();
    await this.getTranslationsData();
    this.getLanguageData(this.availableLanguages)
      .then((data) => {
        this.availableLanguagesConverted = data;
      });
    this.findTranslation();
    this.loaded = true;
  }

  get translationData() {
    return this.translations;
  }

  get translateButtonText() {
    if (this.checkboxValueData) {
      return 'Translate';
    }

    if (this.singularTranslationData === '') {
      return 'Translate singular';
    } if (this.pluralTranslationData === '') {
      return 'Translate plural';
    }

    return 'Translate';
  }

  get checkboxValue() {
    return this.checkboxValueData;
  }

  set checkboxValue(data) {
    this.checkboxValueData = data;
  }

  get inputArr() {
    return this.inputFieldData;
  }

  set inputArr(data) {
    this.inputFieldData = data;
  }

  addNewInput() {
    this.addPropModal = true;
  }

  addNewInputField() {
    if (this.modalNameInputField === 'singular' || this.modalNameInputField === 'plural' || this.modalNameInputField === '') {
      this.addPropModal = false;
      this.modalNameInputField = '';
      return;
    }
    this.inputFieldData.push({ name: this.modalNameInputField.toLowerCase().replace(/[^A-Z0-9_]+/ig, '') });
    this.addPropModal = false;
    this.modalNameInputField = '';
  }

  deleteCustomInput(data: any) {
    this.deletedKeys.push(data.name);
    this.inputArr = this.inputArr.filter((el: any) => el.name !== data.name);
  }

  get viewLanguageName() {
    const [data] = this.availableLanguagesConverted
      .filter((data: any) => data.value === this.getHashValue)
      .map((data: any) => data.languageName);

    return data;
  }

  get breadcrumbs() {
    const { type, translation } = this.$route.params;
    return `${type}.${translation}`;
  }

  async createTranslation(from: string, to: string) {
    if (this.translateItemString === '') {
      return;
    }

    this.$store.commit(TranslateMutations.TRANSLATE_ITEM_OBJECT, {
      text: this.translateItemString, from, to,
    });

    try {
      await this.$store.dispatch('setTranslationItem');
      this.translatedItem = this.$store.getters.getTranslatedItem;

      if (this.singularTranslationData === '') {
        this.singularTranslationData = this.translatedItem;
      } else if (this.pluralTranslationData === '') {
        this.pluralTranslationData = this.translatedItem;
      }
    } catch (e) {
      this.translatedItem = 'Error: This language is not supported!';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getLanguageData(languages: Array<string>) {
    // List of all languages with detailed info
    const allLanguages = CountryLanguage.getLanguages();

    // Get iso639_1 from database languages
    const databaseLanguageValue = languages.map((data) => {
      const [value] = data.split('-');
      return value;
    });

    // Remove duplicates and return array with iso639_1 formatted languages
    const removeDuplicateLanguages = [...new Set(databaseLanguageValue)];

    // Return array with languages and all their details
    return removeDuplicateLanguages
      .flatMap((value) => {
        const languageDetails = allLanguages
          .find((languageItem: any) => languageItem.iso639_1 === value);
        let [languageName] = languageDetails.name;
        languageName = languageName.toString();
        return { languageDetails, languageName, value };
      });
  }

  get getHashValue() {
    const [hashUrlValue] = this.$route.hash.split('#').filter((el) => el !== '');
    return hashUrlValue;
  }

  clearErrors() {
    this.singularError = false;
    this.pluralError = false;
  }

  @Watch('getHashValue')
  async findTranslation() {
    this.clearErrors();
    this.inputFieldData = [];

    this.translatedItem = '';

    if (this.getHashValue) {
      const transData = await this.translations
        .find((translations: any) => translations.language.split('-')[0] === this.getHashValue);

      if (transData.translation.singular === undefined) {
        this.checkboxValueData = true;
        Object.keys(transData.translation)
          .map((translationData: any) => {
            this.inputFieldData.push(
              { name: translationData, data: transData.translation[translationData] },
            );
            return true;
          });
      } else {
        this.checkboxValueData = false;
        this.singularTranslationData = (transData === undefined) ? '' : transData.translation.singular;
        this.pluralTranslationData = (transData === undefined) ? '' : transData.translation.plural;
      }
    }
  }

  async getTranslationsData() {
    this.databaseContent = this.$store.getters.getAttributes;
    const key = this.$route.params.translation;
    const { type } = this.$route.params;

    const translationData = this.databaseContent
      .filter((data: any) => data.type === type)
      .filter((data: any) => data.key === key);

    if (translationData.length === 0) {
      // Temporary solustion if data is empty
      this.$router.back();
    }

    const getLanguages = this.databaseContent
      .filter((data: any) => data.type === undefined)
      .flatMap((data: any) => data.languages);

    this.translations = translationData;

    this.availableLanguages = getLanguages;
  }

  get isItemSaving() {
    switch (this.isSaving) {
      case 0:
        return 'fa fa-spinner fa-pulse';
      case 1:
        return 'fa fa-times';
      case 2:
        return 'fa fa-check';
      case 3:
        return '';
      default:
        return '';
    }
  }

  async saveTranslation() {
    this.clearErrors();
    this.isSaving = 0;

    if (this.pluralTranslationData === '' && this.singularTranslationData === '' && !this.checkboxValueData) {
      this.singularError = true;
      this.pluralError = true;
      this.isSaving = 1;
      return;
    }

    if (this.singularTranslationData === '' && !this.checkboxValueData) {
      this.singularError = true;
      this.isSaving = 1;
      return;
    }

    if (this.pluralTranslationData === '' && !this.checkboxValueData) {
      this.pluralError = true;
      this.isSaving = 1;
      return;
    }

    const translateItem: any = await this.translations
      .find((item: any) => item.language === `${this.getHashValue}-${this.getHashValue.toUpperCase()}`);

    const translateItems: any = await this.translations
      .filter((items: any) => items.language !== `${this.getHashValue}-${this.getHashValue.toUpperCase()}`);

    if (this.checkboxValueData) {
      delete translateItem.translation.singular;
      delete translateItem.translation.plural;

      // Clear all from current translation
      if (translateItem.translation.singular === undefined) {
        Object.keys(translateItem.translation).map((propData: any) => {
          delete translateItem.translation[propData];
          return true;
        });
      }

      this.inputFieldData.map((inputData: any) => {
        translateItem.translation[inputData.name] = inputData.data;
        return inputData;
      });
      this.singularTranslationData = '';
      this.pluralTranslationData = '';

      // Create inputs everywhere
      translateItems.map((item: any) => {
        // eslint-disable-next-line no-param-reassign
        delete item.translation.singular;
        // eslint-disable-next-line no-param-reassign
        delete item.translation.plural;

        Object.keys(item.translation).map((propData: any) => {
          // Only delete keys that needed to be deleted
          this.deletedKeys.map((key: any) => {
            if (key === propData) {
              // eslint-disable-next-line no-param-reassign
              delete item.translation[propData];
              return true;
            }
            return true;
          });
          return true;
        });

        this.inputFieldData.map((inputData: any) => {
          if (item.translation[inputData.name] === undefined) {
            Object.assign(item.translation, { [inputData.name]: '' });
          }
          return inputData;
        });

        this.$store.commit(TranslateMutations.SET_OLD_TRANSALTE_ITEM, item);
        this.$store.dispatch('updateTranslation');

        return item;
      });
    } else {
      if (translateItem.translation.singular === undefined) {
        Object.keys(translateItem.translation).map((propData: any) => {
          delete translateItem.translation[propData];
          return propData;
        });
      }

      translateItems.map((item: any) => {
        if (item.translation.singular === undefined) {
          Object.keys(item.translation).map((propData: any) => {
            // eslint-disable-next-line no-param-reassign
            delete item.translation[propData];

            // eslint-disable-next-line no-param-reassign
            item.translation.singular = '';
            // eslint-disable-next-line no-param-reassign
            item.translation.plural = '';

            return propData;
          });
        }

        this.$store.commit(TranslateMutations.SET_OLD_TRANSALTE_ITEM, item);
        this.$store.dispatch('updateTranslation');

        return item;
      });
      translateItem.translation.plural = this.pluralTranslationData;
      translateItem.translation.singular = this.singularTranslationData;
    }

    const { name, division } = this.$route.params;

    const Obj = this.databaseContent
      .filter((key: any) => key.languages === undefined);

    const newObj = this.databaseContent
      .filter((key: any) => key.languages === undefined)
      .map((key: any) => key.language);
    const language = [...new Set(newObj)];

    const items: any = { data: {} };

    items.company = name;
    items.division = division;

    await Promise.all(language.map((parent: any) => {
      const children = Obj.map((child: any) => child);
      const filterdChildren: any = children.filter((item: any) => item.language === parent);

      // Type data
      const typeData = filterdChildren.map((item: any) => item.type);
      const typeFilterd: any = [...new Set(typeData)];

      // Check if items.data[parent] is set.
      if (items.data[parent] === undefined) {
        items.data[parent] = {};
      }

      // Loop through types
      typeFilterd.map((data: any) => {
        // Append type to language
        Object.assign(items.data[parent], { [data]: {} });
        // Check if types are the same
        filterdChildren
          .filter((itemData: any) => itemData.type === data)
          .map((itemData: any) => {
            // Append key value to attribute
            Object.assign(items.data[parent][data], { [itemData.key]: {} });
            // Append singular and plural to key value
            // Or append newly added extra props
            items.data[parent][data][itemData.key] = itemData.translation;
            return itemData;
          });
        return data;
      });
      return parent;
    }));

    // Commit translate item to mutation
    this.$store.commit(TranslateMutations.SET_OLD_TRANSALTE_ITEM, translateItem);
    await this.$store.dispatch('updateTranslation');

    const versionObj = {
      pk: `${name}#Project`,
      sk: `${division}#languages`,
      languages: language,
      version: Date.now(),
    };

    // Update version
    this.$store.commit(TranslateMutations.CHANGE_VERSION, versionObj);
    await this.$store.dispatch('changeVersionNumber');

    // Upload JSON file to database
    this.$store.commit(TranslateMutations.ADD_TRANSLATION_JSON, items);
    await this.$store.dispatch('addTranslationJSON');

    await this.initRefresh();

    this.isSaving = 2;

    await new Promise((resolve: any) => setTimeout(resolve, 3000));
    this.isSaving = 3;
  }

  async fetch() {
    // Fill this.data with router params
    this.data = {
      company: `${this.$route.params.name}#Project`,
      division: this.$route.params.division,
    };

    // Commit this.data to mutation
    this.$store.commit(TranslateMutations.SET_ROUTE_DATA, this.data);

    // Call action getSpecificTranslations
    await this.$store.dispatch('getSpecificTranslations');
  }
}
