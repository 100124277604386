import { Module } from 'vuex';
import { ProfileState } from './types';
import { RootState } from '../types';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state: ProfileState = {
  sk: [],
  translations: {
    company: '',
    division: '',
  },
  translation: [],
  allTranslations: [],
  missingData: {
    pk: '',
    sk: '',
    key: '',
    type: '',
    language: '',
    translation: {},
  },
  translationObj: {
    pk: '',
    sk: '',
    key: '',
    type: '',
    language: '',
    translation: {},
  },
  singleTranslation: {
    pk: '',
    sk: '',
    key: '',
    type: '',
    language: '',
    translation: {},
  },
  translationJSON: {},
  translateItemObject: {},
  translatedItemObject: {},
  versionObj: {},
  deleteItem: {
    company: '',
    division: '',
    language: '',
    key: '',
    type: '',
  },
};

const translation: Module<ProfileState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};

export { translation as default };
