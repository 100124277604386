/* eslint-disable global-require */
import { themeHelper } from '@index-software/index-core-vue';
import { Component, Vue } from 'vue-property-decorator';
import '@index-software/index-core-vue/dist/style.css';

@Component
// eslint-disable-next-line import/prefer-default-export
export class ThemeMixin extends Vue {
  // eslint-disable-next-line class-methods-use-this
  mounted() {
    themeHelper(
      [
        {
          name: '--color-secondary',
          value: '#f28fbf',
        },
        {
          name: '--ic-auth-image',
          value: `url(${require('@/assets/login-background.jpeg')})`,
        },
        {
          name: '--ic-logo-image',
          value: `url(${require('@/assets/translate-logo.png')})`,
        },
        {
          name: '--sidebar-logo-image',
          value: `url(${require('@/assets/translate-logo.png')})`,
        },
        {
          name: '--sidebar-logo-image-small',
          value: `url(${require('@/assets/translate-logo.png')})`,
        },
        {
          name: '--color-sidebar-background',
          value: '#fff',
        },
        {
          name: '--color-sidebar-menu-text-color',
          value: '#1C4256',
        },
        {
          name: '--color-sidebar-border',
          value: '#F4F6F7',
        },
        {
          name: '--color-primary',
          value: '#8FD4F2',
        },
        {
          name: '--color-sidebar-is-link-text-hover-color',
          value: '#8FD4F2',
        },
        {
          name: '--color-sidebar-is-link-icon-hover-color',
          value: '#8FD4F2',
        },
        {
          name: '--color-sidebar-toggler-background-hover',
          value: '#8FD4F2',
        },
        {
          name: '--color-error',
          value: '#C8553D',
        },
      ],
    );
  }
}
