import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueI18n from 'vue-i18n';
import axios from '@/http';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerComponents';
import './registerServiceWorker';
import '@fortawesome/fontawesome-pro/css/all.css';

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(VeeValidate);

const messages = {
  en: {
    addendum: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      drag_here_or: {
        singular: 'Drag here or',
        plural: 'Drag here or',
      },
      choose: {
        singular: 'choose',
        plural: 'choose',
      },
    },
    attributes: {
      file: {
        singular: 'file',
        plural: 'files',
      },
    },
    messages: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      unpermitted_files_added: {
        singular: 'Unpermitted file added',
        plural: 'Unpermitted files added',
      },
      // eslint-disable-next-line @typescript-eslint/camelcase
      to_many_files: {
        singular: 'To many files',
        plural: 'To many files',
      },
    },
  },
  nl: {
    addendum: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      drag_here_or: {
        singular: 'Sleep hier of',
        plural: 'Sleep hier of',
      },
      choose: {
        singular: 'kies',
        plural: 'kiezen',
      },
    },
    attributes: {
      file: {
        singular: 'bestand',
        plural: 'bestanden',
      },
    },
    messages: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      unpermitted_files_added: {
        singular: 'Niet toegestaand bestands type toegevoegd',
        plural: 'Niet toegestaand bestands type toegevoegd',
      },
      // eslint-disable-next-line @typescript-eslint/camelcase
      to_many_files: {
        singular: 'Te veel bestanden',
        plural: 'Te veel bestanden',
      },
    },
  },
};

Vue.prototype.$http = axios;

const i18n = new VueI18n({
  locale: 'nl', // set locale
  messages, // set locale messages
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
