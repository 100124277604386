
















































































































import {
  Component, Vue, VModel, Watch,
} from 'vue-property-decorator';
import CountryLanguage from 'country-language';
import Multiselect from 'vue-multiselect';
import { Sidebar } from '@/interfaces/Sidebar';
import { TranslateMutations } from '@/store/translations/mutations';
import { SidebarMutations } from '@/store/sidebar/mutations';
import { DeleteItem } from '../interfaces/DeleteItem';
import '../../node_modules/vue-multiselect/dist/vue-multiselect.min.css';

@Component({
  components: {
    Multiselect,
  },
})
export default class AddItemModel extends Vue {
  @VModel({ type: Boolean }) modalStatus!: boolean;

  categoryType = 'language';

  sidebarParents = [];

  selectedCountries = [];

  countries = [];

  isLoading = false;

  url = '';

  databaseContent = {};

  addedLanguages = {};

  isSaving = 3;

  isDisabled = true;

  enableSecurity = false;

  deleteInput = '';

  error = '';

  urlData = '';

  sidebarData: any = [];

  inputSidebarData: any = [];

  dropDown = false;

  selected: any = '';

  selectedItem = false;

  saveItem: any = '';

  deleteSidebarSecurity = '';

  selectedAppLabel(): string {
    if (this.selected !== '') {
      return this.selected.text;
    }

    return 'Choose a sidebar item';
  }

  @Watch('deleteSidebarSecurity')
  deleteSidebarItem() {
    if (this.deleteSidebarSecurity === 'delete') {
      this.enableSecurity = true;
    } else {
      this.enableSecurity = false;
    }
  }

  async deleteSidebarInput(item: any) {
    await this.getUrl();

    const labelChildName = [] as any[];

    const databaseData = await this.fetch();
    const langData = databaseData.filter((language: any) => language.languages !== undefined);
    const { name, division } = this.$route.params;
    let noJSON = false;
    let jsonData: any = {};
    let deleteSidebarItem: DeleteItem;

    try {
      jsonData = await this.$store.dispatch('getTranslationsObject', { project: name, division });
    } catch (e) {
      noJSON = true;
    }

    if (item.type !== 'LABEL') {
      const deleteSidebarItem: DeleteItem = {
        company: name,
        division: item.divisionType,
        language: 'deleteSidebar',
        key: item.parent.split('#')[0],
        type: item.oldTextValue,
      };

      labelChildName.push(item.oldTextValue);

      // If there is a JSON file
      if (!noJSON) {
        langData[0].languages.map((lang: any) => {
          delete jsonData[lang][item.text];
          return true;
        });
      }

      this.$store.commit(SidebarMutations.DELETE_SIDEBAR_ITEM, deleteSidebarItem);
      await this.$store.dispatch('deleteSidebarItem');
    } else {
      const deleteSidebarItemParent: DeleteItem = {
        company: name,
        division: item.divisionType,
        language: 'deleteParent',
        key: '',
        type: item.text,
      };

      // Loop trough all children
      const children = this.sidebarData
        .filter((item: any) => item.type === 'CUSTOM')
        .map((child: any) => child);

      if (children.length > 0) {
        await Promise.all(children.map(async (child: any) => {
          const [parentChild] = child.parent.split('#');
          const typeChild = (child.oldTextValue ? child.oldTextValue : child.text);

          // Check if parent and has that child
          if (deleteSidebarItemParent.type === parentChild) {
            console.log(typeChild);
            console.log('ik ga');
            labelChildName.push(typeChild);
            const deleteChild: DeleteItem = {
              company: name,
              division: item.divisionType,
              language: 'deleteSidebar',
              key: parentChild,
              type: typeChild,
            };

            // If there is a JSON file
            if (!noJSON) {
              langData[0].languages.map((lang: any) => {
                delete jsonData[lang][typeChild];
                return true;
              });
            }

            // Delete child sidebar item
            this.$store.commit(SidebarMutations.DELETE_SIDEBAR_ITEM, deleteChild);
            await this.$store.dispatch('deleteSidebarItem');
          }
        }));
      }
      this.$store.commit(SidebarMutations.DELETE_SIDEBAR_ITEM, deleteSidebarItemParent);
      await this.$store.dispatch('deleteSidebarItem');
    }

    if (!noJSON) {
      const newJsonObj = {
        company: name,
        data: jsonData,
        division,
      };
      this.$store.commit(TranslateMutations.ADD_TRANSLATION_JSON, newJsonObj);
      await this.$store.dispatch('addTranslationJSON');
    }

    // Get all translate items
    const translateItems = await this.$store.getters.getAttributes;
    const itemsToDelete = translateItems.filter((item: any) => item.languages === undefined);

    itemsToDelete.map(async (sidebarItem: any) => {
      // Check if names are the same.
      // Delete only the children under specific label

      labelChildName.map(async (label: any) => {
        if (label === sidebarItem.type) {
          const deleteObj: DeleteItem = {
            company: name,
            division: item.divisionType,
            language: sidebarItem.language,
            key: sidebarItem.key,
            type: sidebarItem.type,
          };

          // Delete old item
          this.$store.commit(TranslateMutations.DELETE_ITEM, deleteObj);
          await this.$store.dispatch('deleteTranslation');
        }
        return true;
      });
    });

    await new Promise((resolve: any) => setTimeout(resolve, 100));

    this.$store.commit(SidebarMutations.SET_UPDATE_SIDEBAR);
    this.enableSecurity = false;
    this.deleteSidebarSecurity = '';
    this.closeModal();
  }

  @Watch('saveItem')
  clearData() {
    this.selected = '';
    this.selectedItem = false;
  }

  clickSelect(data: Sidebar) {
    this.selected = data;
    this.dropDown = !this.dropDown;
    // Show input under dropdown
    this.selectedItem = true;
  }

  filterIt(arr: any, searchKey: any) {
    return arr.filter((obj: any) => Object.keys(obj).some((key) => obj[key].includes(searchKey)));
  }

  async addFind() {
    await this.getUrl();

    const databaseData = await this.fetch();
    const langData = databaseData.filter((language: any) => language.languages !== undefined);

    let jsonData: any = {};
    let noJSON = false;
    const {
      name, division,
    } = this.$route.params;

    // Get JSON file
    try {
      jsonData = await this.$store.dispatch('getTranslationsObject', { project: name, division });
    } catch (e) {
      noJSON = true;
    }

    // Get all translate items
    const translateItems = await this.$store.getters.getAttributes;

    const newSidebarItem = this.selected;

    if (this.selected.type === 'CUSTOM') {
      const [parent] = this.selected.parent.split('#');
      const type = (this.selected.oldTextValue ? this.selected.oldTextValue : this.selected.text);

      const deleteSidebarItem: DeleteItem = {
        company: name,
        division,
        language: 'deleteSidebar',
        key: parent,
        type,
      };

      // Delete sidebar item
      this.$store.commit(SidebarMutations.DELETE_SIDEBAR_ITEM, deleteSidebarItem);
      await this.$store.dispatch('deleteSidebarItem');

      // Change sk for siderbar item
      newSidebarItem.sk = `${parent}#${this.selected.text}#${division}`;
    }

    await new Promise((resolve: any) => setTimeout(resolve, 100));

    if (this.selected.type === 'LABEL') {
      const [type] = this.selected.sk.split('#');

      const deleteSidebarItem: DeleteItem = {
        company: name,
        division,
        language: 'deleteParent',
        key: '',
        type,
      };

      // Delete parent sidebar item
      this.$store.commit(SidebarMutations.DELETE_SIDEBAR_ITEM, deleteSidebarItem);
      await this.$store.dispatch('deleteSidebarItem');

      newSidebarItem.sk = `${this.selected.text}#${division}`;

      // Change children when parent is changed
      const children = this.sidebarData
        .filter((item: any) => item.type === 'CUSTOM')
        .map((child: any) => child);

      // Change child sk/parent/eventname
      if (children.length > 0) {
        // change data
        await Promise.all(children.map(async (child: any) => {
          const [parentChild] = child.parent.split('#');
          const typeChild = (child.oldTextValue ? child.oldTextValue : child.text);

          const deleteChild: DeleteItem = {
            company: name,
            division,
            language: 'deleteSidebar',
            key: parentChild,
            type: typeChild,
          };

          const newChild = child;
          newChild.sk = `${this.selected.text}#${child.text}#${child.divisionType}`;
          newChild.parent = `${this.selected.text}#${child.divisionType}`;
          newChild.eventName = `${this.selected.text}${child.text}`;

          // Delete child sidebar item
          this.$store.commit(SidebarMutations.DELETE_SIDEBAR_ITEM, deleteChild);
          await this.$store.dispatch('deleteSidebarItem');

          // Add new sidebar child
          this.$store.commit(SidebarMutations.SET_NEW_SIDEBAR_DATA, newChild);
          await this.$store.dispatch('addSidebarCategory');
        }));
      }
    }

    // Add new sidebar item
    this.$store.commit(SidebarMutations.SET_NEW_SIDEBAR_DATA, newSidebarItem);
    await this.$store.dispatch('addSidebarCategory');

    if (!noJSON) {
      langData[0].languages.map((lang: any) => {
        if (
          this.selected.oldTextValue !== this.selected.text
          && this.selected.oldTextValue !== undefined
        ) {
          const data = Object
            .assign(
              jsonData[lang], { [this.selected.text]: jsonData[lang][this.selected.oldTextValue] },
            )[jsonData[lang][this.selected.oldTextValue]];
          delete jsonData[lang][this.selected.oldTextValue];
        }
        return true;
      });
    }

    // Delete items in sidebar division
    if (this.selected.type === 'CUSTOM') {
      const itemsToReplace = translateItems
        .filter((item: any) => item.type === this.selected.oldTextValue);
      itemsToReplace.map(async (item: any) => {
        const deleteObj: DeleteItem = {
          company: name,
          division,
          language: item.language,
          key: item.key,
          type: item.type,
        };

        // Delete old item
        this.$store.commit(TranslateMutations.DELETE_ITEM, deleteObj);
        await this.$store.dispatch('deleteTranslation');

        const editedItem = item;
        editedItem.type = this.selected.text;
        editedItem.sk = `${division}#${item.language}#${item.key}#${this.selected.text}`;

        // Add item in database
        this.$store.commit(TranslateMutations.ADD_SINGLE_TRANSLATION, editedItem);
        this.$store.dispatch('addTranslation');
        return editedItem;
      });
    }

    // Change all items
    if (!noJSON) {
      const newJsonObj = {
        company: name,
        data: jsonData,
        division,
      };
      this.$store.commit(TranslateMutations.ADD_TRANSLATION_JSON, newJsonObj);
      await this.$store.dispatch('addTranslationJSON');
    }

    await new Promise((resolve: any) => setTimeout(resolve, 500));

    if (this.$route.path !== `/translate/${name}/${division}`) {
      this.$router.push({
        name: 'TranslateDetail',
        params: {
          name,
          division,
        },
      }).catch(() => null);
    }

    this.saveItem += 1;

    await new Promise((resolve: any) => setTimeout(resolve, 500));

    this.$store.commit(SidebarMutations.SET_UPDATE_SIDEBAR);
    this.closeModal();
  }

  @Watch('modalStatus')
  async fetchSidebarData(value: boolean) {
    this.error = '';

    await this.getUrl();
    const { division } = this.$route.params;

    // Main sidebar items
    await this.fetchSidebar(this.urlData).then((data: any) => {
      this.sidebarData = data.filter((item: any) => item.divisionType === division);
      const sidebarParent = data
        .filter((item: any) => item.divisionType === division)
        .filter((item: any) => item.parent === undefined);
      const currentMessage = data
        .filter((item: any) => item.divisionType === division)
        .filter((item: any) => item.parent !== undefined);

      const newArr = currentMessage
        .map((message: any) => ({ ...message, oldTextValue: message.text }));
      this.sidebarData = [...newArr, ...sidebarParent];
    });
    const data = await this.fetch();
    const [newData] = data.filter((language: any) => language.languages !== undefined);
    const langCode = newData.languages.map((lang: any) => {
      const [code] = lang.split('-');
      return code;
    });

    const allLanguages = CountryLanguage.getLanguages();

    const languageData = await langCode.flatMap((code: any) => {
      const allLanguagesObject = allLanguages
        .filter((language: any) => language.iso639_1 === code)
        .flatMap((lang: any) => {
          const langCod = lang.iso639_1;
          const [langName] = lang.name;

          if (langCod !== '') {
            return { code: langCod, name: langName };
          }
          return lang;
        });
      return allLanguagesObject;
    });

    this.selectedCountries = languageData;
  }

  async fillSidebarLanguages(languages: Array<string>) {
    const { name, division } = this.$route.params;
    const languageArray = languages.map((language: any) => `${language.code.toLowerCase()}-${language.code.toUpperCase()}`);

    const projectLanguages = {
      pk: `${name}#Project`,
      sk: `${division}#languages`,
      languages: languageArray,
    };

    return projectLanguages;
  }

  async fillTranslation() {
    const data = await this.fetch();
    const [languages] = data.filter((data: any) => data.languages !== undefined);

    const languagesArrayFromLocal = this.selectedCountries
      .map((language: any) => `${language.code.toLowerCase()}-${language.code.toUpperCase()}`);

    // ADD TRANSLATIONS FOR ADDED LANGUAGE(S)
    const difference = languagesArrayFromLocal.filter((x: any) => !languages.languages.includes(x));

    const copyData = data.filter((data: any) => data.languages === undefined);
    const removeDuplicateData = copyData
      .filter((v: any, i: any, a: any) => a
        .findIndex((t: any) => (t.key === v.key && t.type === v.type)) === i);

    const { division } = this.$route.params;

    return removeDuplicateData.flatMap((data: any) => difference.flatMap((dif: any) => {
      const newSK = `${division}#${dif}#${data.key}#${data.type}`;

      const newData = {
        pk: data.pk,
        sk: newSK,
        translation: {
          singular: '',
          plural: '',
        },
        key: data.key,
        language: dif,
        type: data.type,
      };

      return newData;
    }));
  }

  async checkDeleteLanguage() {
    const data = await this.fetch();
    const [languages] = data.filter((data: any) => data.languages !== undefined);

    const languagesArrayFromLocal = this.selectedCountries
      .map((language: any) => `${language.code.toLowerCase()}-${language.code.toUpperCase()}`);

    const difference = languages.languages.filter((x: any) => !languagesArrayFromLocal.includes(x));

    const copyData = data.filter((data: any) => data.languages === undefined);

    return difference
      .flatMap((lang: any) => copyData.filter((item: any) => item.language === lang));
  }

  get isItemSaving() {
    switch (this.isSaving) {
      case 0:
        return 'fa fa-spinner fa-pulse';
      case 1:
        return 'fa fa-times';
      case 2:
        return 'fa fa-check';
      case 3:
        return '';
      default:
        return '';
    }
  }

  @Watch('selectedCountries')
  async checkSecurity() {
    const itemsForDelete = await this.checkDeleteLanguage();

    if (itemsForDelete.length > 0) {
      this.enableSecurity = true;
    }
  }

  @Watch('deleteInput')
  checkDeleteInput() {
    if (this.deleteInput === 'delete') {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  async saveChanges() {
    this.isSaving = 0;

    let noJSON = false;
    let jsonData: any = {};
    const {
      name, division,
    } = this.$route.params;

    if (this.selectedCountries.length === 0) {
      this.error = 'Error: No language is selected, please fill in a language.';
      this.isSaving = 1;
      return;
    }

    // Get JSON file
    try {
      jsonData = await this.$store.dispatch('getTranslationsObject', { project: name, division });
    } catch (e) {
      noJSON = true;
    }

    // Get all items that need to be copied
    const items = await this.fillTranslation();

    const itemsForDelete = await this.checkDeleteLanguage();

    // Delete language(s) from JSON file
    // Check if language need to be deleted.
    if (itemsForDelete.length > 0) {
      // eslint-disable-next-line array-callback-return
      await itemsForDelete.map((item: any) => {
        if (!noJSON) {
          if (item.language !== undefined) {
            delete jsonData[item.language];
          }
        }

        const deleteObj: DeleteItem = {
          company: name,
          division,
          language: item.language,
          key: item.key,
          type: item.type,
        };

        this.$store.commit(TranslateMutations.DELETE_ITEM, deleteObj);
        this.$store.dispatch('deleteTranslation');
      });

      if (!noJSON) {
        const newJsonObj = {
          company: name,
          data: jsonData,
          division,
        };

        this.$store.commit(TranslateMutations.ADD_TRANSLATION_JSON, newJsonObj);
        await this.$store.dispatch('addTranslationJSON');
      }
    }

    // Check if Items is available
    if (items.length > 0) {
      await items.map(async (item: any) => {
        // Insert new items in database
        this.$store.commit(TranslateMutations.ADD_SINGLE_TRANSLATION, item);
        await this.$store.dispatch('addTranslation');
      });
    }

    // Add new language to settings
    const languagesInput = await this.fillSidebarLanguages(this.selectedCountries);
    this.$store.commit(SidebarMutations.SET_NEW_SIDEBAR_DATA, languagesInput);
    await this.$store.dispatch('addSidebarCategory');

    const versionObj = {
      pk: languagesInput.pk,
      sk: languagesInput.sk,
      languages: languagesInput.languages,
      version: Date.now(),
    };

    // Update version
    this.$store.commit(TranslateMutations.CHANGE_VERSION, versionObj);
    await this.$store.dispatch('changeVersionNumber');

    await new Promise((resolve: any) => setTimeout(resolve, 500));

    // Update sidebar
    this.$store.commit(SidebarMutations.SET_UPDATE_SIDEBAR);
    this.isSaving = 2;

    this.modalStatus = false;

    this.deleteInput = '';
    this.isSaving = 3;

    if (this.$route.path !== `/translate/${name}/${division}`) {
      this.$router.push({
        name: 'TranslateDetail',
        params: {
          name,
          division,
        },
      }).catch(() => null);
    }
  }

  // Fill countries variable with search value
  asyncFind(query: any) {
    this.isLoading = true;
    this.searchLanguages(query).then((response: any) => {
      this.countries = response;
      this.isLoading = false;
    });
  }

  // return array with possible languages user entered
  // eslint-disable-next-line class-methods-use-this
  async searchLanguages(name: any): Promise<object[]> {
    const newArr = this.listOfLanguages
      .filter((item: any) => item.name.toLowerCase().indexOf(name.toLowerCase()) > -1);

    return newArr;
  }

  // Return all languages with a iso639_1 value
  // eslint-disable-next-line class-methods-use-this
  get listOfLanguages() {
    const allLanguages = CountryLanguage.getLanguages();

    const allLanguagesObject = allLanguages.map((language: any) => {
      const langCode = language.iso639_1;
      const [langName] = language.name;

      if (langCode !== '') {
        return { code: langCode, name: langName };
      }

      return false;
    });

    return allLanguagesObject.filter((item: any) => item !== false);
  }

  // Return parent data from sidebar
  async fetch() {
    // Fill this.data with router params
    const data = {
      company: `${this.$route.params.name}#Project`,
      division: this.$route.params.division,
    };

    // Commit this.data to mutation
    this.$store.commit(TranslateMutations.SET_ROUTE_DATA, data);

    // Call action getSpecificTranslations
    await this.$store.dispatch('getSpecificTranslations');

    return this.$store.getters.getAttributes;
  }

  async getUrl() {
    // Fill this.urlData with router params or with Menu
    this.urlData = (this.$route.path === '/' ? 'Menu' : `${this.$route.params.name}#Menu`);
  }

  async fetchSidebar(url: string): Promise<Sidebar[]> {
    // Commit this.urlData to mutation
    this.$store.commit(SidebarMutations.SET_SIDEBAR_PATH, url);

    // Call action getSpecificTranslations
    await this.$store.dispatch('getMenuItems');

    // Receive all sidebar text
    return this.$store.getters.getSidebarData;
  }

  // Save new sidebar item
  async saveNewSidebarItem() {
    // Commit update to store so app knows to update data
    this.$store.commit(SidebarMutations.SET_UPDATE_SIDEBAR);
    this.closeModal();
  }

  closeModal() {
    this.modalStatus = false;
  }
}
