





import Component, { mixins } from 'vue-class-component';
import SidebarHome from '@/components/SidebarHome.vue';
import { ThemeMixin } from './components/mixins/themeSetup';

@Component({
  components: {
    SidebarHome,
  },
})
export default class App extends mixins(ThemeMixin) {
}

