import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import authModule from '@index-software/auth/src';
import authTemplate from '@index-software/auth/src/templates/Auth.vue';
import store from '@/store';
import Home from '../views/Home.vue';

Vue.use(VueRouter);
const authRoutes = authModule.router.routes(authTemplate);

const routes: Array<RouteConfig> = [
  authRoutes,
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/translate/:name/:division',
    name: 'TranslateDetail',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (TranslteDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "TranslteDetail" */ '../views/TranslateDetail.vue'),
    props: true,
  },
  {
    path: '/translate/:name/:division/:type',
    name: 'TranslateSidebar',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (TranslteDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "TranslteDetail" */ '../views/TranslateDetail.vue'),
    props: true,
  },
  {
    path: '/translate/:name/:division/:type/:translation',
    name: 'Translations',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (TranslteDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "TranslteDetail" */ '../views/TranslateDetail.vue'),
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // User is authenticated
  if (store.getters[authModule.store.types.GETTER_AUTH_STATUS] === 'AUTHENTICATED') {
    if (to.name === 'Login') {
      next({ name: 'Home' });
    }
  } else if (to.matched.some((route) => route.meta.requiresAuth)) {
    // Unset all auth tokens
    store.commit(authModule.store.types.MUTATE_USER_AUTH_UNSET);
    // redirect to login
    return next({ name: 'Login' });
  }

  return next();
});

export default router;
