













import { Component, Vue, Watch } from 'vue-property-decorator';
import SidebarHome from '@/components/SidebarHome.vue';
import TranslateContent from '@/components/TranslateContent.vue';
import TranslateSidebar from '@/components/TranslateSidebar.vue';
import { TranslateMutations } from '@/store/translations/mutations';
import { SidebarMutations } from '@/store/sidebar/mutations';

@Component({
  components: {
    SidebarHome,
    TranslateContent,
    TranslateSidebar,
  },
})
export default class TranslateDetail extends Vue {
  open = true;

  data = {};

  category = '';

  // Activate when division is changed
  @Watch('$route.params.division')
  clearData() {
    this.$store.commit(SidebarMutations.SET_SIDEBAR_CATEGORY, '');
  }

  async fetch() {
    // Fill this.data with router params
    this.data = {
      company: `${this.$route.params.name}#Project`,
      division: this.$route.params.division,
    };

    // Commit this.data to mutation
    this.$store.commit(TranslateMutations.SET_ROUTE_DATA, this.data);

    // Call action getSpecificTranslations
    await this.$store.dispatch('getSpecificTranslations');
  }
}
