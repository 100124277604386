import { Module } from 'vuex';
import { SidebarState } from './types';
import { RootState } from '../types';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state: SidebarState = {
  translations: [],
  path: '',
  missingList: [],
  category: '',
  newSidebarItem: {},
  updatePage: 0,
  searchValue: '',
  databaseSearchValue: '',
  deleteSidebarItem: {
    company: '',
    division: '',
    language: '',
    key: '',
    type: '',
  },
};

const sidebar: Module<SidebarState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};

export { sidebar as default };
