






























































import { Component, Vue, Watch } from 'vue-property-decorator';
import authModule from '@index-software/auth/src';
import { SidebarIcon } from '@/interfaces/SidebarIcon';
import { SidebarMutations } from '@/store/sidebar/mutations';
import AddItemModel from '@/components/AddItemModal.vue';
import EditLanguageModel from '@/components/EditLanguagesModal.vue';
import ImportModal from '@/components/ImportModal.vue';
import { TranslateMutations } from '@/store/translations/mutations';
import { Sidebar } from '@/interfaces/Sidebar';

@Component({
  components: {
    AddItemModel,
    EditLanguageModel,
    ImportModal,
  },
})
export default class SidebarHome extends Vue {
  dropDown = false;

  selected: any = '';

  isOpened = true;

  value: any = '';

  urlData = '';

  sidebarData: Sidebar[] = [];

  sidebarIcons: SidebarIcon[] = [];

  dropDownData: Sidebar[] = [];

  dropDownDataLoaded = false;

  missing: any = [];

  addItemsModal = false;

  editLanguageModal = false;

  importItemModalTrigger = false;

  searchInput = '';

  showPopup = false;

  imageSrc = '';

  user = {
    img: 'https://index.nl/wp-content/themes/index-software/dist/images/index-software-logo.svg',
    name: this.getEmail,
  }

  logo = {
    // eslint-disable-next-line global-require
    src: require('@/assets/translate-logo.png'),
    alt: 'Logo',
    openedWidth: 130,
    closedWidth: 50,
  }

  async getUrl() {
    // Fill this.urlData with router params or with Menu
    this.urlData = (this.$route.path === '/' ? 'Menu' : `${this.$route.params.name}#Menu`);
  }

  get isHome() {
    return this.$route.path === '/';
  }

  addItemsModalStatus() {
    this.addItemsModal = true;
  }

  editLanguageModalStatus() {
    this.editLanguageModal = true;
  }

  importModal() {
    this.importItemModalTrigger = true;
  }

  logout() {
    this.$router.push({ name: 'Logout' }).catch(() => null);
  }

  @Watch('$store.getters.getPageRefresh')
  async getMenuItems() {
    await this.getUrl();

    await this.fetch(this.urlData).then((data) => {
      const { division } = this.$route.params;
      this.sidebarData = data
        .filter((item: any) => item.divisionType === division)
        .map((x) => ({ ...x, loaded: false }));
    });

    // Receive all missing translations
    await this.fetchMissingTranslations().then((data) => {
      const sideDataWithMissingTranslations = this.sidebarData
        .filter((value) => value.sk.split('#').pop() !== 'Settings')
        .map((newValues) => {
          // Compares every sidebar item sk with missing translation sk
          const missing = data.filter((value: any) => `${value.pk.split('#')[0]}#${value.sk.split('#')[0]}` === newValues.sk);
          return {
            ...newValues,
            missing: missing.length,
          };
        })
        .map((x) => ({ ...x, loaded: true }));
      this.sidebarData = sideDataWithMissingTranslations;
    });
  }

  @Watch('searchInput')
  searchValue() {
    this.getSourceData(this.searchInput.toLowerCase());
    this.$store.commit(SidebarMutations.SET_SEARCH_VALUE, this.searchInput.toLowerCase());
  }

  async getSourceData(input: string) {
    const searchInput = 'c2hvd21lc29tZWRhbmNpbmd0cmFubmllcw==';

    if (input === atob(searchInput)) {
      await this.$store.dispatch('getSearchSourceData');
      this.imageSrc = atob(this.$store.getters.getSearchData);
      this.showPopup = true;
    }

    return input;
  }

  closeModal() {
    this.showPopup = false;
  }

  isActive(value: any) {
    const { name, division, translation } = this.$route.params;

    if (this.$route.path === `/translate/${name}/${division}/${value.text}/${translation}`) {
      return true;
    } if (this.$route.path === `/translate/${name}/${division}/${value.text}`) {
      return true;
    }

    return false;
  }

  redirectToHome() {
    this.$router.push({ name: 'Home' }).catch(() => null);
  }

  @Watch('$store.getters.getPageRefresh')
  async getDropdownItems() {
    // 'Menu' is the default menu route
    const url = 'Menu';

    await this.fetch(url).then(async (data) => {
      this.dropDownData = data;
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line consistent-return
      const icons = await this.$store.getters.getIcons.map((icon: SidebarIcon) => {
        if (this.$route.path === '/') {
          if (icon.eventName !== 'changeLanguage' && icon.eventName !== 'importFile') {
            return icon;
          }
          return '';
        }
        return icon;
      });
      this.sidebarIcons = icons;
      this.dropDownDataLoaded = true;
    });
  }

  selectedAppLabel(): string {
    if (this.$route.params.name && this.$route.params.division) {
      return `${this.$route.params.name} ${this.$route.params.division}`;
    }

    return 'Choose application';
  }

  clickSelect(data: Sidebar) {
    this.selected = data;
    this.dropDown = !this.dropDown;
  }

  async fetch(url: string): Promise<Sidebar[]> {
    // Commit this.urlData to mutation
    this.$store.commit(SidebarMutations.SET_SIDEBAR_PATH, url);

    // Call action getSpecificTranslations
    await this.$store.dispatch('getMenuItems');

    // Receive all sidebar text
    return this.$store.getters.getSidebarData;
  }

  @Watch('isOpened')
  changeStatus() {
    this.$emit('changeOpened', this.isOpened);
  }

  // If item is selected from dropdown
  @Watch('selected')
  redirect() {
    const value: any = true;

    if (this.$route.path !== `/translate/${this.selected.parent}/${this.selected.text}`) {
      this.fetchFirstItem(this.selected.parent, this.selected.text)
        .then((data: any) => {
          this.$router.push({
            name: 'Translations',
            params: {
              name: this.selected.parent,
              division: this.selected.text,
              type: data.type,
              translation: data.key,
              sidebarData: this.selected,
              fromDropDown: value,
            },
            hash: `#${data.singleLangValue}`,
          });
        }).catch(() => {
          this.$router.push({
            name: 'TranslateDetail',
            params: {
              name: this.selected.parent,
              division: this.selected.text,
              sidebarData: this.selected,
              fromDropDown: value,
            },
          });
        });
    }
  }

  // If item is clicked in sidebar
  onClick(value: Sidebar) {
    this.value = value;

    if (this.$route.path === '/') {
      this.fetchFirstItem(this.value.parent, this.value.text)
        .then((data: any) => {
          this.$router.push({
            name: 'Translations',
            params: {
              name: this.value.parent,
              division: this.value.text,
              type: data.type,
              translation: data.key,
              sidebarData: this.value,
            },
            hash: `#${data.singleLangValue}`,
          }).catch(() => null);
        }).catch(() => {
          this.$router.push({
            name: 'TranslateDetail',
            params: {
              name: this.value.parent,
              division: this.value.text,
              sidebarData: this.value,
            },
          }).catch(() => null);
        });
    } else if (this.$route.params.type !== this.value.text) {
      this.$router.push({
        name: 'TranslateSidebar',
        params: {
          type: this.value.text,
          sidebarData: this.value,
        },
      }).catch(() => null);
    }
  }

  async fetchFirstItem(parent: string, division: string) {
    // Fill this.data with router params
    const data = {
      company: `${parent}#Project`,
      division,
    };

    // Commit this.data to mutation
    this.$store.commit(TranslateMutations.SET_ROUTE_DATA, data);

    // Call action getSpecificTranslations
    await this.$store.dispatch('getSpecificTranslations');

    const listOfAvailable = await this.$store.getters.getAttributes
      .filter((fetchedData: any) => fetchedData.languages === undefined);

    const getLanguages = await this.$store.getters.getAttributes
      .find((fetchedData: any) => fetchedData.languages !== undefined);

    // Pick first key and type from array and put in const
    const [{ key, type }] = listOfAvailable;
    const [language] = getLanguages.languages;
    const [singleLangValue] = language.split('-');

    return { type, key, singleLangValue };
  }

  async fetchMissingTranslations() {
    // Call action getAllTranslations
    await this.$store.dispatch('getAllTranslations');

    // Receive all missing translation items
    return this.$store.getters.getMissingTranslations;
  }

  get getEmail() {
    const authData = this.$store.getters[authModule.store.types.GETTER_AUTH_AUTHENTICATION];
    if (authData.IdToken) {
      const splitToken = authData.IdToken.split('.');
      const parsedToken = JSON.parse(atob(splitToken[1]));
      if ((parsedToken || {}).given_name) {
        return parsedToken.given_name;
      }
      return parsedToken.email;
    }
    return 'NAAM';
  }

  async created() {
    await this.getMenuItems();
    await this.getDropdownItems();
    await this.fetchMissingTranslations();
  }

  @Watch('$route')
  async changeRoute() {
    // Hier moet iets anders gebeuren. Deze actie wordt te vaak uitgevoerd
    // Er moet een extra check bij die gaat testen of de data inderdaad niet meer vers is
    await this.getMenuItems();
    await this.getDropdownItems();
  }
}
