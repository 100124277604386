import { ActionTree } from 'vuex';
import axios from 'axios';
import { RootState } from '../types';
import { ProfileState } from './types';
import { TranslateMutations } from './mutations';
import stack from '../../../../../cdk.out/stack.json';

const actions: ActionTree<ProfileState, RootState> = {
  async getSpecificTranslations({ commit, state }) {
    const response = await axios.get(`/companies/${encodeURIComponent(state.translations.company)}/${state.translations.division}`);
    commit(TranslateMutations.SET_TRANSLATION_DATA, response.data.resultList.Items);
  },
  async getAllTranslations({ commit }) {
    const response = await axios.get('/missing-translations');
    commit(TranslateMutations.SET_ALL_TRANSLATIONS, response.data.resultList.Items);
  },
  async updateTranslation({ commit, state }) {
    const response = await axios
      .put(`/companies/${encodeURIComponent(state.translations.company)}/${state.translations.division}/${state.translationObj.key}`, state.translationObj);
    commit(TranslateMutations.SET_NEW_TRANSLATE_ITEM, response.data.dataResult);
  },
  async addTranslation({ state }) {
    const response = await axios.post('/sidebar', state.singleTranslation);
    return response;
  },
  async addTranslationJSON({ state }) {
    await axios.post('/translations', state.translationJSON);
  },
  async setTranslationItem({ commit, state }) {
    const response = await axios.post('/translate-text', state.translateItemObject);
    commit(TranslateMutations.TRANSLATED_ITEM_OBJECT, response.data.resultList);
  },
  async changeVersionNumber({ state }) {
    return axios.post('/sidebar', state.versionObj);
  },
  async deleteTranslation({ state }) {
    const response = await axios.delete(`/delete/${state.deleteItem.company}/${state.deleteItem.division}/${state.deleteItem.language}/${state.deleteItem.key}/${state.deleteItem.type}`);
    return response;
  },
  async getTranslationsObject({ state }, payload) {
    const translateHTTP = axios.create();
    translateHTTP.defaults.headers = {
      'Access-Control-Allow-Origin': '*',
      'Cache-Control': 'no-cache',
      Expires: 0,
      Pragma: 'no-cache',
    };
    try {
      const response = (await translateHTTP.get(`https://${stack.translateBackendStack.TranslationsBucket}.s3.eu-central-1.amazonaws.com/${payload.project}${payload.division}.json`, {
        responseType: 'json',
      })).data.data;
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export { actions as default };
