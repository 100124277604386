



















import {
  Component, Vue, VModel, Watch,
} from 'vue-property-decorator';
import { SidebarMutations } from '@/store/sidebar/mutations';
import { TranslateMutations } from '@/store/translations/mutations';

@Component
export default class AddKeyModel extends Vue {
  @VModel({ type: Boolean }) modalStatus!: boolean;

  translationInput = '';

  error = '';

  saving = false;

  // Get all key data from specifc division
  // Return all key data from specifc division
  async getLanguages() {
    const { name, division } = this.$route.params;

    const urlData = {
      company: `${name}#Project`,
      division,
    };

    this.$store.commit(TranslateMutations.SET_ROUTE_DATA, urlData);

    await this.$store.dispatch('getSpecificTranslations');

    return this.$store.getters.getAttributes;
  }

  // Return filled translation object
  async fillSingleTranslation(translation: string): Promise<object> {
    const { name, division, type } = this.$route.params;

    // Get all key data from getLanguages
    // Filter all languages from the key data
    const availableLanguages = await this.getLanguages()
      .then((data) => data
        .filter((value: any) => value.languages)
        .flatMap((languageArray: any) => languageArray.languages));

    // Loop through all availableLanguages and fill newTranslationObj
    const newTranslationObj = await availableLanguages.map((lang: any) => {
      const singleTranslation = {
        pk: `${name}#Project`,
        sk: `${division}#${lang}#${translation}#${type}`,
        translation: {
          singular: '',
          plural: '',
        },
        key: translation,
        language: lang,
        type,
      };

      return singleTranslation;
    });

    return newTranslationObj;
  }

  async saveTranslationKey() {
    if (!this.saving) {
      this.saving = true;
      this.error = '';
      const translateKey = this.translationInput.toLowerCase().replace(/[^A-Z0-9_]+/ig, '');
      const { type } = this.$route.params;

      if (translateKey.length === 0) {
        this.error = 'ERROR: Item name is empty.';
      }

      await this.getLanguages().then((data: any) => {
        data.map((keyData: any) => {
          if (keyData.key === translateKey && keyData.type === type) {
            this.error = 'ERROR: Item name already exitst. Please try another name.';
          }
          return keyData;
        });
      });

      if (!this.error) {
      // Fill translation object with data from input
        const fillSingleTranslation: any = await this.fillSingleTranslation(translateKey);

        // Loop through all languages in fillSingleTranslation object
        // Add translation objects to database and wait till finished
        await Promise.all(fillSingleTranslation.map(async (lang: any) => {
          this.$store.commit(TranslateMutations.ADD_SINGLE_TRANSLATION, lang);
          await this.$store.dispatch('addTranslation');
        }));

        // Commit update to store so app knows to update data
        this.$store.commit(SidebarMutations.SET_UPDATE_SIDEBAR);
        const { name, division, type } = this.$route.params;

        const lang = await this.getLanguages();

        const languages = lang
          .find((key: any) => key.languages !== undefined).languages
          .map((lang: any) => lang);
        const [firstLanguage] = languages;
        const [langSplit] = firstLanguage.split('-');

        this.$router.push({
          name: 'Translations',
          params: {
            name,
            division,
            type,
            translation: translateKey,
          },
          hash: `#${langSplit}`,
        }).catch(() => null);
        this.closeModal();
        this.translationInput = '';
      }
      this.saving = false;
    }
  }

  @Watch('$route')
  routeChangeResetInput() {
    this.translationInput = '';
    this.error = '';
  }

  closeModal() {
    this.modalStatus = false;
  }
}
