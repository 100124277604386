import { MutationTree } from 'vuex';
import { DeleteItem } from '@/interfaces/DeleteItem';
import { Sidebar } from '@/interfaces/Sidebar';
import { SidebarState } from './types';

export enum SidebarMutations {
  SET_SIDEBAR_DATA = 'SET_TRANSLATE_DATA',
  SET_SIDEBAR_PATH = 'SET_SIDEBAR_PATH',
  SET_SIDEBAR_MISSING_DATA = 'SET_SIDEBAR_MISSING_DATA',
  SET_SIDEBAR_CATEGORY = 'SET_SIDEBAR_CATEGORY',
  SET_NEW_SIDEBAR_DATA = 'SET_NEW_SIDEBAR_DATA',
  SET_UPDATE_SIDEBAR = 'SET_UPDATE_SIDEBAR',
  SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
  RECEIVED_SEARCH_DATA = 'RECEIVED_SEARCH_DATA',
  DELETE_SIDEBAR_ITEM = 'DELETE_SIDEBAR_ITEM',
}

const mutations: MutationTree<SidebarState> = {
  [SidebarMutations.SET_SIDEBAR_DATA](state, payload: Sidebar[]) {
    state.translations = payload;
  },
  [SidebarMutations.SET_SIDEBAR_PATH](state, payload: string) {
    state.path = payload;
  },
  [SidebarMutations.SET_SIDEBAR_MISSING_DATA](state, payload: Array<string>) {
    state.missingList = payload;
  },
  [SidebarMutations.SET_SIDEBAR_CATEGORY](state, payload: string) {
    state.category = payload;
  },
  [SidebarMutations.SET_NEW_SIDEBAR_DATA](state, payload: object) {
    state.newSidebarItem = payload;
  },
  [SidebarMutations.SET_UPDATE_SIDEBAR](state) {
    state.updatePage += 1;
  },
  [SidebarMutations.SET_SEARCH_VALUE](state, payload: string) {
    state.searchValue = payload;
  },
  [SidebarMutations.RECEIVED_SEARCH_DATA](state, payload: string) {
    state.databaseSearchValue = payload;
  },
  [SidebarMutations.DELETE_SIDEBAR_ITEM](state, payload: DeleteItem) {
    state.deleteSidebarItem = payload;
  },
};

export { mutations as default };
